<div class="col-lg-12 ">
    <div class="shop-product-wrap row">
      <div class="col-lg-3 col-6"
        *ngFor="let item of products"
      >
        <div
          class="slide-item mini-slide"
          (click)="productPage(item)"
          [ngClass]="{ 'd-none': item.opened }"
        >
          <div class="single_product">
            <i
              class=" fa-heart favorite_product"
              [ngClass]="{'fas':item.is_favourite,'far':!item.is_favourite}"
              (click)="pushToFavoriteProducts(item, $event)"
            ></i>
            <div class="product-img" *ngIf="item.attachments.length">
              <a>
                <img
                  class="primary-img"
                  src="{{ imagePath + item.attachments[0].path }}"
                />
                <img
                  class="secondary-img"
                  src="{{
                    imagePath +
                      item.attachments[item.attachments.length - 1].path
                  }}"
                />
              </a>
              <div class="add-actions">
                <ul>
                  <li>
                    <a
                      class="hiraola-add_cart"
                      (click)="addToCart($event, item)"
                      ><i class="ion-bag"></i
                    ></a>
                  </li>
                  <li class="quick-view-btn">
                    <a><i class="ion-eye"></i></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="hiraola-product_content">
              <div class="product-desc_info">
                <h6>
                  <a class="product-name">{{ item.name }}</a>
                </h6>
                <div class="price-box">
                  <span class="new-price">{{
                    item.variants[0].peso_price
                      | currency: "ARS":"symbol-narrow":"1.2-2"
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>