import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { SiteService } from '@siteServices/site/site.service';
import { DialogService } from 'primeng/dynamicdialog';
import { take } from 'rxjs/operators';
import { PreorderDialogComponent } from './preorder-dialog/preorder-dialog.component';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  total = 0;
  products;
  imgPath = environment.base;
  preorder;
  mustPreorder = false;
  loading = false;
  constructor(
    private router: Router,
    private siteService: SiteService,
    private dialogService: DialogService
  ) {
    this.getAll();
  }

  getAll() {
    this.loading = true;
    this.siteService
      .getCart()
      .pipe(take(1))
      .subscribe((res) => {
        this.total = 0;
        this.products = res.map((x) => {
          this.total += x.total_price;
          x.maxQuantity = x.variant.inventories_count -
          parseInt(x.variant.reserved_sum_quantity)
          return x;
        });
        this.loading = false;
      });
  }

  o;

  ngOnInit(): void {}

  quantityPlus(item) {
    if (this.loading) {
      return;
    }
    this.loading = true;

    let send = { quantity: item.quantity + 1, id: item.id };
    this.siteService
      .updateCartProduct(send)
      .pipe(take(1))
      .subscribe((res) => {
        item.quantity++;
        this.getAll();
      });
  }
  quantityMinus(item) {
    if (this.loading) {
      return;
    }

    if (item.quantity > 1) {
      this.loading = true;
      let send = { quantity: item.quantity - 1, id: item.id };
      this.siteService
        .updateCartProduct(send)
        .pipe(take(1))
        .subscribe((res) => {
          this.loading = false;
          item.quantity--;
          this.getAll();
        });
    }
  }

  removeProduct(item) {
    this.siteService
      .removeFromCart(item)
      .pipe(take(1))
      .subscribe((res) => {
        this.getAll();
      });
  }

  checkout() {
    let send = 0;
    
    if (this.preorder) {
      send = 1;
    }else{
      this.products.forEach(element => {
        if(element.quantity>element.maxQuantity){
          this.mustPreorder = true;
        }
      });
    }

    if(this.mustPreorder && !this.preorder){
      return
    }

    
    this.siteService
      .checkout(send)
      .pipe(take(1))
      .subscribe((res) => {
        if (res?.redirect) {
          window.location.href = res.redirect;
        } else {
          this.openDialog();
        }
      });
  }

  openDialog() {
    this.dialogService
      .open(PreorderDialogComponent, {
        styleClass: '',
        width: '1100px',
        style: { 'max-width': '100vw' },
      })
      .onClose.pipe(take(1))
      .subscribe((res) => {
        this.router.navigate(['/shop']);
      });
  }
}
