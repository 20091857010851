
<div class="hiraola-footer_area">
    <div class="footer-top_area">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="footer-widgets_info">

                        <div class="footer-widgets_logo">
                            <a href="#">
                                <img src="assets/images/footer/logo/1.png" alt="Hiraola's Footer Logo">
                            </a>
                        </div>

                        <div class="widget-short_desc">
                            
                        </div>
                        <div class="hiraola-social_link">
                            <ul>
                                <li class="facebook">
                                    <a href="https://www.facebook.com/Casa-Sofia-Design-113194723788105/" target="_blank" title="Facebook">
                                        <i class="fab fa-facebook"></i>
                                    </a>
                                </li>
                                <li class="twitter">
                                    <a href="https://wa.me/5491156065513" data-toggle="tooltip" target="_blank" title="Whatsapp">
                                        <i class="ion-social-whatsapp"></i>
                                    </a>
                                </li>
                                <li class="twitter">
                                    <a href="https://wa.me/5491158499924" data-toggle="tooltip" target="_blank" title="Whatsapp">
                                        <i class="ion-social-whatsapp"></i>
                                    </a>
                                </li>
                                <li class="instagram">
                                    <a href="https://instagram.com/casasofiadesign?r=nametag" target="_blank" title="Instagram">
                                        <i class="fab fa-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="footer-widgets_area">
                        <div class="row">
                            <!-- <div class="col-lg-3">
                                <div class="footer-widgets_title">
                                    <h6>Product</h6>
                                </div>
                                <div class="footer-widgets">
                                    <ul>
                                        <li><a href="#">Prices drop</a></li>
                                        <li><a href="#">New products</a></li>
                                        <li><a href="#">Best sales</a></li>
                                        <li><a href="#">Contact us</a></li>
                                    </ul>
                                </div>
                            </div> -->
                            <div class="col-lg-5">
                                <div class="footer-widgets_info">
                                    <div class="footer-widgets_title">
                                        <h6 i18n>About Us</h6>
                                    </div>
                                    <div class="widgets-essential_stuff">
                                        <ul>
                                            <li class="hiraola-address"><i class="ion-ios-location"></i><span i18n>Address: </span>
                                                <ng-container i18n>Libertad 243 Local 29 , CABA , Argentina</ng-container> </li>
                                            <li class="hiraola-phone"><i class="ion-ios-telephone"></i><span i18n>Call Us: </span> <a href="tel://+541143822426">+54 11 4382 2426</a>
                                            </li>
                                            <li class="hiraola-phone"><i class="ion-ios-telephone"></i><span i18n>Call Us: </span> <a href="tel://+541143829518">+54 11 4382 9518</a>
                                            </li>
                                            <li class="hiraola-email"><i class="ion-android-mail"></i><span i18n>Email:</span> <a href="mailto://info@sofiadesign.com.ar">info@sofiadesign.com.ar</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="footer-widgets_title">
                                <a routerLink="/help" class="help" >Help</a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom_area">
        <div class="container">
            <div class="footer-bottom_nav">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="payment">
                            <a href="#">
                                <img src="assets/images/footer/payment/Mastercard-logo.png" alt="Hiraola's Payment Method">                               
                                <img src="assets/images/footer/payment/Visa-Logo.png" alt="Hiraola's Payment Method">
                                <img src="assets/images/footer/payment/Mercadopago-logo.png" alt="Hiraola's Payment Method">
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="copyright">
                            <span><ng-container i18n>Copyright</ng-container>  &copy; {{date|date:'y'}} <a href="#" i18n>Sofia Design.</a> <ng-container i18n>All rights reserved.</ng-container> </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>