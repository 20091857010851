import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {  tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    constructor(private router: Router, private toast:MessageService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.includes('/admin/')) {
            return next.handle(req).pipe(tap(
                (event) => {
                    if(event.type!==0){
                        if(req.method==='POST'){
                            ;
                            this.toast.add({
                                severity: 'success',
                                summary: $localize`Success`,
                                detail: $localize`Successed Request`,
                              });  
                        }
    
                        if(req.method==='DELETE'){
                            this.toast.add({
                                severity: 'success',
                                summary: $localize`Deleted`,
                                detail: $localize`Deleted`,
                              });  
                        }
                        if(req.method==='PUT' || req.method==='PATCH'){
                            this.toast.add({
                                severity: 'success',
                                summary: $localize`Updated`,
                                detail: $localize`Updated`,
                              });  
                        }
                    }
                   
                },
                (err) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status == 401) {
                            localStorage.removeItem('admin_token')
                            this.router.navigate(['/admin/login'])
                        }else{
                            this.toast.add({
                                severity: 'error',
                                summary: $localize`Error`,
                                detail: $localize`Failed`,
                              });
                        }
                    }
                })
            )
        } else {
            return next.handle(req).pipe(tap(
                (event) => {
                },
                (err) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status == 401) {
                            localStorage.removeItem('sofd_token')
                            this.router.navigate(['/login'])
                        }
                    }
                })
            )
        }
        return next.handle(req)
    }


}
