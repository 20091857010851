<div class="hiraola-cart-area">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <form action="javascript:void(0)">
          <div class="table-content table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="hiraola-product-remove" i18n>remove</th>
                  <th class="hiraola-product-thumbnail" i18n>images</th>
                  <th class="cart-product-name" i18n>Product</th>
                  <th class="hiraola-product-price" i18n>Unit Price</th>
                  <th class="hiraola-product-price" i18n>Comment</th>
                  <th class="hiraola-product-quantity" i18n>Quantity</th>
                  <th class="hiraola-product-subtotal" i18n>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of products">
                  <td class="hiraola-product-remove">
                    <a (click)="removeProduct(item)"
                      ><i class="fa fa-trash"></i
                    ></a>
                  </td>
                  <td class="hiraola-product-thumbnail">
                    <img
                      width="100"
                      src="{{ imgPath + item.attachment.path }}"
                      alt="{{ item.name }}"
                    />
                  </td>
                  <td class="hiraola-product-name">
                    <a href="javascript:void(0)"
                      >{{ item.code }}</a
                    >
                    <div class="variant-options">
                      <span
                        *ngFor="
                          let attribueValue of item.variant_attribute_values
                        "
                        >{{ attribueValue.attribute.name }} :
                        {{ attribueValue.name }}
                      </span>
                    </div>
                  </td>
                  <td class="hiraola-product-price">
                    <span class="amount">{{ item.unit_price |currency:'ARS':'symbol-narrow':'1.2-2' }}</span>
                  </td>
                  <td class="hiraola-product-price">
                    <span class="amount">{{ item.comment}}</span>
                  </td>
                  <td class="quantity">
                    <label i18n>Quantity</label>
                    <div class="cart-plus-minus">
                      <input
                        class="cart-plus-minus-box"
                        readonly
                        [(ngModel)]="item.quantity"
                        [ngModelOptions]="{ standalone: true }"
                        type="text"
                      />
                      <div class="dec qtybutton" (click)="quantityMinus(item)">
                        <i class="fa fa-angle-down"></i>
                      </div>
                      <div class="inc qtybutton" (click)="quantityPlus(item)">
                        <i class="fa fa-angle-up"></i>
                      </div>
                    </div>
                  </td>
                  <td class="product-subtotal">
                    <span class="amount">
                      <ng-container *ngIf="!loading">{{
                        item.total_price |currency:'ARS':'symbol-narrow':'1.2-2'
                      }}</ng-container>
                      <i *ngIf="loading" class="fa-spin fa fa-spinner"></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-5 ml-auto">
              <div class="cart-page-total">
                <h2 i18n>Cart total</h2>
                <ul>
                  <li class="text-right">
                    <ng-container *ngIf="!loading">{{ total|currency:'ARS':'symbol-narrow':'1.2-2' }}</ng-container>
                    <i *ngIf="loading" class="fa-spin fa fa-spinner"></i>
                    <span class="float-left"
                      ><input
                        type="checkbox"
                        [(ngModel)]="preorder"
                        [ngModelOptions]="{ standalone: true }"
                      />
                      <ng-container i18n> Preorder</ng-container>
                    </span>
                  </li>
                </ul>
                <div *ngIf="mustPreorder" class="text-danger">Some products are out of stock please enable the Preorder Checkbox </div>

                <a href="javascript:void(0)" (click)="checkout()" i18n
                  >Proceed to checkout</a
                >
                 
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
