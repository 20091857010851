import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { AuthService } from '@siteServices/auth/auth.service';
import { SiteService } from '@siteServices/site/site.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: { '(window:scroll)': 'track($event)' },
})
export class HeaderComponent implements OnInit {
  @Input() landing: boolean = false;
  categories;
  search = '';
  visible;
  fixed = false;
  cartCount = 0;
  cart;
  total = 0;

  sidebar;
  selectedCategories = [];

  imgPath = environment.base;
  isLoggedIn = false;
  constructor(
    private siteService: SiteService,
    private route: ActivatedRoute,
    private authService:AuthService
  ) {
    this.isLoggedIn = authService.isAuthenticated();
  }

  logout(){
    this.authService.logout();
  }

  getCart() {
    this.siteService
      .getCart()
      .pipe(take(1))
      .subscribe((res) => {
        this.total = 0;
        this.cartCount = res.length;
        this.cart = res.map((x) => {
          this.total += x.total_price;
          return x;
        });
      });
  }

  removeProduct(item) {
    this.siteService
      .removeFromCart(item)
      .pipe(take(1))
      .subscribe((res) => {
        this.getCart();
      });
  }

  ngOnInit(): void {
    if (!this.landing) {
      this.siteService
        .getCategories()
        .pipe(take(1))
        .subscribe((res) => {
          this.categories = res;
          this.route.queryParams.subscribe((queryParams) => {
            this.selectedCategories = [];
            if (queryParams.category) {
              this.categories.forEach((element) => {
                let result = this.searchCategory(element, queryParams.category);
                if (result) {
                  this.selectedCategories.push(result.data.id);
                  this.selectedCategories.push(element.data.id);
                }
              });
            }
          });
        });
      this.getCart();
      this.siteService.cartCount$.subscribe((res) => {
        this.cartCount += res;
      });
    }
  }

  track(event) {
    if (window.pageYOffset > 149) {
      this.fixed = true;
    } else {
      this.fixed = false;
    }
  }

  scroll(x) {
    window.scroll({ top: x });
  }

  getKey(event) {
    this.search = event;
  }

  checkRouterActive(id, type = false) {
    if (
      type &&
      [...new Set(this.selectedCategories)].length == 1 &&
      this.selectedCategories.includes(id)
    ) {
      return 'router-link-active';
    }
    if (this.selectedCategories.includes(id) && !type) {
      return 'router-link-active';
    }
    return '';
  }

  searchCategory(element, id) {
    if (element.data.id == id) {
      return element;
    } else if (element.children != null) {
      var i;
      var result = null;
      for (i = 0; result == null && i < element.children.length; i++) {
        result = this.searchCategory(element.children[i], id);
      }
      return result;
    }
    return null;
  }
}
