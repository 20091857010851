import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './pages/landing/landing.component';
import { SiteComponent } from './site.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { AccountComponent } from './pages/account/account.component';
import { FavoritesComponent } from './pages/account/favorites/favorites.component';
import { AuthGuard } from './guards/auth.guard';
import { DashComponent } from './pages/account/dash/dash.component';
import { AddressComponent } from './pages/account/address/address.component';
import { OrdersComponent } from './pages/account/orders/orders.component';
import { DetailsComponent } from './pages/account/details/details.component';
import { ShopComponent } from './pages/shop/shop.component';
import { CartComponent } from './pages/cart/cart.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { PaymentErrorComponent } from './pages/account/payment-error/payment-error.component';
import { PaymentSuccessComponent } from './pages/account/payment-success/payment-success.component';
import { HelpComponent } from './pages/help/help.component';
import { NotFoundComponent } from '../not-found/not-found.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'help', component: HelpComponent },
  { path: '404', component: NotFoundComponent },
  {
    path: '',
    component: SiteComponent,
    children: [
      { path: 'shop', component: ShopComponent, canActivate: [AuthGuard] },
      { path: 'login', component: LoginComponent },
      { path: 'forgot', component: ForgotPasswordComponent },
      { path: 'registration', component: RegisterComponent },
      { path: 'cart', component: CartComponent },
      { path: 'help', component: HelpComponent },
      {
        path: 'account',
        component: AccountComponent,
        canActivate: [AuthGuard],
        children: [
          { path: '', redirectTo: 'dash', pathMatch: 'full' },
          { path: 'dash', component: DashComponent },
          { path: 'address', component: AddressComponent },
          { path: 'orders', component: OrdersComponent },
          { path: 'details', component: DetailsComponent },
          { path: 'payment-error', component: PaymentErrorComponent },
          { path: 'payment-success', component: PaymentSuccessComponent },
          { path: 'favorites', component: FavoritesComponent },
        ],
      },
    ],
  },
   { path: '**', redirectTo: '404' },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SiteRoutingModule {}
