import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SiteService } from '@siteServices/site/site.service';
import { take } from 'rxjs/operators';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {

  user: any;
  form: FormGroup
  constructor(private toast:MessageService, private siteService: SiteService, fb: FormBuilder) {

    this.form = fb.group({
      name: [null, Validators.required],
      email: [null, Validators.required],
      phone: [null],
      website: [null],
      old_password: [null],
      password: [null],
      password_confirmation: [null]
    },{ validators: this.checkPasswords })

    this.siteService.getUserDetails().pipe(take(1)).subscribe(res => {
      this.user = res;
      this.form.patchValue(this.user);
    })
  }

  checkPasswords(group: FormGroup) { 
    
    const password = group.get('password').value;
    const confirmPassword = group.get('password_confirmation').value;
    const oldPassword = group.get('old_password').value;

    if(!password){
      return null
    }

    return oldPassword && password === confirmPassword ? null : { notSame: true };
  }

  ngOnInit(): void {
  }

  save() {
    if (this.form.valid) {
      this.siteService.updateUserDetails(this.form.value).pipe(take(1)).subscribe(res => {
        this.user = res;
        this.form.patchValue(this.user);
        this.form.controls.old_password.patchValue(null),
        this.form.controls.password.patchValue(null),
        this.form.controls.password_confirmation.patchValue(null),
        this.toast.add({severity:'success', summary:$localize`Success`, detail:$localize`Updated Successfuly`});
      })
    }

  }

}
