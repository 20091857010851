<div class="container mt-5">
  <div *ngIf="categories">
    <h1 *ngIf="findCategory()">{{ selectedCategory }}</h1>
  </div>
  <div class="row">
    <div class="col-lg-3 order-1 order-lg-1 d-none d-lg-block">
      <div class="hiraola-sidebar-catagories_area">
        <div class="hiraola-sidebar_categories">
          <div class="hiraola-categories_title">
            <h5 i18n>Price</h5>
          </div>
          <div class="price-filter">
            <p-slider
              [(ngModel)]="price"
              max="40000"
              (onSlideEnd)="setPrices()"
              [range]="true"
            ></p-slider>
            <p class="mt-3">
              {{ price[0] | currency: "ARS":"symbol-narrow":"1.2-2" }} -
              {{ price[1] | currency: "ARS":"symbol-narrow":"1.2-2" }}
            </p>
          </div>
        </div>
        <div class="category-module hiraola-sidebar_categories">
          <div class="category-module_heading">
            <h5>Categories</h5>
          </div>
          <div class="module-body">
            <ul class="mobile-menu" *ngFor="let category of categories">
              <li class="menu-item-has-children">
                <span
                  (click)="category.expanded = !category.expanded"
                  class="menu-expand category-expand"
                >
                  <i
                    class="fa fa-plus mr-2"
                    *ngIf="category.children.length"
                    [ngClass]="{
                      'fa-plus': !category.expanded,
                      'fa-minus': category.expanded
                    }"
                  ></i
                ></span>
                <a
                  (click)="visible = false"
                  [routerLink]="['/shop']"
                  [queryParams]="{ category: category.data.id }"
                  queryParamsHandling="merge"
                >
                  <span class="mm-text">{{ category.data.name }}</span>
                </a>
                <ul
                  *ngFor="let c1 of category.children"
                  class="sub-menu active"
                  [ngClass]="{
                    'menu-close': !category.expanded,
                    'menu-open': category.expanded
                  }"
                >
                  <li class="menu-item-has-children ml-3">
                    <a
                      (click)="visible = false"
                      [routerLink]="['/shop']"
                      [queryParams]="{ category: c1.data.id }"
                      queryParamsHandling="merge"
                      routerLinkActive="router-link-active"
                    >
                      <span class="mm-text">{{ c1.data.name }}</span>
                    </a>
                    <ul class="sub-menu">
                      <li *ngFor="let c2 of c1.children" class="ml-3">
                        <a
                          (click)="visible = false"
                          [routerLink]="['/shop']"
                          [queryParams]="{ category: c2.data.id }"
                          queryParamsHandling="merge"
                        >
                          <span class="mm-text">{{ c2.data.name }}</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-9 order-2 order-lg-2">
      <div class="shop-product-wrap row">
        <div
          [ngClass]="{ 'col-lg-3 col-6': !item.opened, 'col-12': item.opened }"
          *ngFor="let item of products"
        >
          <div
            class="slide-item mini-slide"
            (click)="productPage(item)"
            [ngClass]="{ 'd-none': item.opened }"
          >
            <div class="single_product">
              <i
                class=" fa-heart favorite_product"
                [ngClass]="{'fas':item.is_favourite,'far':!item.is_favourite}"
                (click)="pushToFavoriteProducts(item, $event)"
              ></i>
              <div class="product-img" *ngIf="item.attachments.length">
                <a>
                  <img
                    class="primary-img"
                    src="{{ imagePath + item.attachments[0].path }}"
                  />
                  <img
                    class="secondary-img"
                    src="{{
                      imagePath +
                        item.attachments[item.attachments.length - 1].path
                    }}"
                  />
                </a>
                <div class="add-actions">
                  <ul>
                    <li>
                      <a
                        class="hiraola-add_cart"
                        (click)="addToCart($event, item)"
                        ><i class="ion-bag"></i
                      ></a>
                    </li>
                    <li class="quick-view-btn">
                      <a><i class="ion-eye"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="hiraola-product_content">
                <div class="product-desc_info">
                  <h6>
                    <a class="product-name">{{ item.name }}</a>
                  </h6>
                  <div class="price-box">
                    <span class="new-price">{{
                      item.variants[0].peso_price
                        | currency: "ARS":"symbol-narrow":"1.2-2"
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div #pageBottom></div>
