import { ICategory } from '@adminServices/categories/category';
import { IProduct } from '@adminServices/products/product';
import { IUser } from '@adminServices/users/user';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  API_URL = environment.api_url;
  constructor(private http: HttpClient) {}

  cartCount = new BehaviorSubject<number>(0);
  cartCount$ = this.cartCount.asObservable();

  getCategories() {
    return this.http
      .get<{ data: ICategory[] }>(this.API_URL + 'categories')
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  getProducts(filters): Observable<any> {
    return this.http
      .get<{ data: { data: IProduct[]; currentPage: number } }>(
        this.API_URL + 'products',
        { params: filters }
      )
      .pipe(
        map((response) => {
          response.data.data = response.data.data.map((x) => {
            x.variants = x.variants.map((variant) => {
              if (variant.reserved_sum_quantity === null) {
                variant.reserved_sum_quantity = 0;
              }
              return variant;
            });
            if (x.is_favourite) {
              x.is_favourite = true;
            } else {
              x.is_favourite = false;
            }
            return x;
          });
          return response.data;
        })
      );
  }

  getFavorites(): Observable<any> {
    return this.http
      .get<{ data: IProduct[]; currentPage: number }>(
        this.API_URL + 'user/favourites'
      )
      .pipe(
        map((response) => {
          response.data = response.data.map((x) => {
            x.variants = x.variants.map((variant) => {
              if (variant.reserved_sum_quantity === null) {
                variant.reserved_sum_quantity = 0;
              }
              return variant;
            });
            if (x.is_favourite) {
              x.is_favourite = true;
            } else {
              x.is_favourite = false;
            }
            return x;
          });
          return response.data;
        })
      );
  }

  getUserDetails() {
    return this.http.get<{ data: IUser[] }>(this.API_URL + 'user').pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  updateUserDetails(entity) {
    return this.http
      .patch<{ data: IUser[] }>(this.API_URL + 'user', entity)
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  getOrders() {
    return this.http.get<{ data: any }>(this.API_URL + 'orders').pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  getAddresses() {
    return this.http.get<{ data: any }>(this.API_URL + 'addresses').pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  createAddress(address) {
    return this.http
      .post<{ data: any }>(this.API_URL + 'addresses', address)
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  updateAddress(address) {
    return this.http
      .patch<{ data: any }>(this.API_URL + 'addresses/' + address.id, address)
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  addToCart(product) {
    return this.http.post<{ data: any }>(this.API_URL + 'cart', product).pipe(
      map((response) => {
        this.cartCount.next(1);
        return response.data;
      })
    );
  }

  getCart() {
    return this.http.get<{ data: any }>(this.API_URL + 'cart').pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  removeFromCart(product) {
    return this.http
      .delete<{ data: any }>(this.API_URL + 'cart/' + product.id)
      .pipe(
        map((response) => {
          this.cartCount.next(-1);
          return response.data;
        })
      );
  }

  updateCartProduct(product) {
    return this.http
      .patch<{ data: any }>(this.API_URL + 'cart/' + product.id, product)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getSiteData() {
    return this.http.get<{ data: any }>(this.API_URL + 'site-data').pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  checkout(preorder) {
    return this.http
      .get<{ data: any }>(this.API_URL + 'checkout?preorder=' + preorder)
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  updateOrderStatus(back_info) {
    return this.http
      .post<{ data: any }>(this.API_URL + 'update-payment', back_info)
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  favourite(id) {
    return this.http
      .post<{ data: any }>(this.API_URL + 'product/favourite/' + id, null)
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }
}
