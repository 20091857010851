import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SiteService } from '@siteServices/site/site.service';
import { MessageService } from 'primeng/api';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  addresses: any;
  billing;
  shipping;
  billingForm: FormGroup;
  shippingForm: FormGroup
  constructor(private siteService: SiteService, fb: FormBuilder, private toast:MessageService) {
    this.billingForm = fb.group({
      postal_code: [null, Validators.required],
      state: [null, Validators.required],
      city: [null, Validators.required],
      street: [null, Validators.required],
      apartment: [null, Validators.required],
      type: ['billing']
    })

    this.shippingForm = fb.group({
      postal_code: [null, Validators.required],
      state: [null, Validators.required],
      city: [null, Validators.required],
      street: [null, Validators.required],
      apartment: [null, Validators.required],
      type: ['shipping']
    })
    this.getAll();

  }

  ngOnInit(): void {
  }

  getAll() {
    this.siteService.getAddresses().pipe(take(1)).subscribe(res => {
      this.billing = res.filter(x => { return x.type == 'billing' });
      if (this.billing[0]) {
        this.billingForm.addControl('id', new FormControl);
        this.billingForm.patchValue(this.billing[0]);
      }
      this.shipping = res.filter(x => { return x.type == 'shipping' });
      if (this.shipping[0]) {

        this.shippingForm.addControl('id', new FormControl);
        this.shippingForm.patchValue(this.shipping[0]);
      }

    })
  }

  save(form: FormGroup) {
    if (form.value.id) {
      if (form.valid) {
        this.siteService.updateAddress(form.value).subscribe(res => {
          this.getAll();
          this.toast.add({severity:'success', summary:$localize`Success`, detail:$localize`Updated Successfuly`});
        })
      }
    }else{
      if (form.valid) {
        this.siteService.createAddress(form.value).subscribe(res => {
          this.getAll();
          this.toast.add({severity:'success', summary:$localize`Success`, detail:$localize`Created Successfuly`});
        })
      }
    }
  }

}
