<div class="tab-pane fade show" id="account-address" role="tabpanel" aria-labelledby="account-address-tab">
    <div class="myaccount-address">
        <p i18n>The following addresses will be used on the checkout page by default.</p>
        <div class="row">
            <div class="col-12">
                <h4 class="small-title" i18n>BILLING ADDRESS</h4>
                <div class="myaccount-details">
                    <form [formGroup]="billingForm" class="hiraola-form">
                        <div class="hiraola-form-inner">
                            <div class="single-input">
                                <label for="account-details-firstname" i18n>State</label>
                                <input formControlName="state" type="text" id="account-details-firstname">
                            </div>
                            <div class="single-input">
                                <label for="account-details-email" i18n>City</label>
                                <input formControlName="city" type="text" id="account-details-email">
                            </div>
                            <div class="single-input">
                                <label for="account-details-email" i18n>Street</label>
                                <input formControlName="street" type="text" id="account-details-email">
                            </div>
                            <div class="single-input">
                                <label for="account-details-email" i18n>House/Apartment</label>
                                <input formControlName="apartment" type="email" id="account-details-email">
                            </div>
                            <div class="single-input">
                                <label for="account-details-email" i18n>ZIP/Postal Code</label>
                                <input formControlName="postal_code" type="email" id="account-details-email">
                            </div>
                            <div class="single-input">
                                <button class="hiraola-btn hiraola-btn_dark" (click)="save(billingForm)"><span i18n>SAVE
                                CHANGES</span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-12 pt-5">
                <h4 class="small-title" i18n>SHIPPING ADDRESS</h4>
                <div class="myaccount-details">
                    <form [formGroup]="shippingForm" class="hiraola-form">
                        <div class="hiraola-form-inner">
                            <div class="single-input">
                                <label for="account-details-firstname" i18n>State</label>
                                <input formControlName="state" type="text" id="account-details-firstname">
                            </div>
                            <div class="single-input">
                                <label for="account-details-email" i18n>City</label>
                                <input formControlName="city" type="text" id="account-details-email">
                            </div>
                            <div class="single-input">
                                <label for="account-details-email" i18n>Street</label>
                                <input formControlName="street" type="text" id="account-details-email">
                            </div>
                            <div class="single-input">
                                <label for="account-details-email" i18n>House/Apartment</label>
                                <input formControlName="apartment" type="email" id="account-details-email">
                            </div>
                            <div class="single-input">
                                <label for="account-details-email" i18n>ZIP/Postal Code</label>
                                <input formControlName="postal_code" type="email" id="account-details-email">
                            </div>
                            <div class="single-input">
                                <button class="hiraola-btn hiraola-btn_dark" (click)="save(shippingForm)"><span i18n>SAVE
                                CHANGES</span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>