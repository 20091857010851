import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { SiteService } from '@siteServices/site/site.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { config } from 'rxjs';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss'],
})
export class ProductDialogComponent implements OnInit {
  comment = '';
  selectedProduct = this.config.data.entity;
  quantity = 1;
  maxQuantity = 1;
  item = this.config.data.entity;
  imagePath = environment.base;
  selectedFilters = {};
  price = this.selectedProduct.price;
  variant = null;
  filters = {};
  filteredVariations = this.selectedProduct.variants;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private siteService: SiteService,
    private toast: MessageService
  ) {}

  ngOnInit(): void {
    this.selectedProduct.variants = this.selectedProduct.variants.map((x) => {
      x.tooltip = x.attribute_values
        .map((val) => val.attribute.name + ': ' + val.name)
        .join('\n');
      return x;
    });

    if (this.selectedProduct.variants.length == 1) {
      this.variant = this.selectedProduct.variants[0].id;
      this.getVariant(this.selectedProduct.variants[0]);
    }
    this.setFilters(this.selectedProduct.variants);
  }

  setFilters(variants) {
    let attrs = {};
    variants.forEach((variant) => {
      let attr = variant.attribute_values.forEach((attribute_value) => {
        if (attrs[attribute_value.attribute.id]) {
          if (
            !attrs[attribute_value.attribute.id].values.find(
              (x) => x.id === attribute_value.id
            )
          ) {
            attrs[attribute_value.attribute.id].values.push(attribute_value);
          }
        } else {
          attrs[attribute_value.attribute.id] = {
            name: attribute_value.attribute.name,
            values: [attribute_value],
          };
        }
      });
    });
    this.filters = attrs;
  }

  addToCart() {
    if (this.variant === null) {
      return;
    }
    let filters = [];
    Object.keys(this.selectedFilters).forEach((index) => {
      if (this.selectedFilters[index] != 0) {
        filters.push(+this.selectedFilters[index]);
      }
    });
    let send = {
      quantity: this.quantity,
      product_id: this.selectedProduct.id,
      comment: this.comment,
      variant_id: this.variant,
    };
    this.siteService.addToCart(send).subscribe((res) => {
      this.toast.add({
        severity: 'success',
        summary: $localize`Success`,
        detail: $localize`Added to cart`,
      });
      this.ref.close();
    });
  }

  getVariant(variation) {
    this.maxQuantity =
      variation.inventories_count - parseInt(variation.reserved_sum_quantity);
    this.price = variation.peso_price;
    if (this.quantity > this.maxQuantity) {
      this.quantity = 1;
    }
  }
  quantityPlus() {
    if (this.quantity < this.maxQuantity) {
      this.quantity++;
    }
  }
  quantityMinus() {
    if (this.quantity > 1) {
      this.quantity--;
    }
  }

  filterSelect(value, key) {
    if (this.selectedFilters[key]) {
      if (parseInt(value) > 0) {
        this.selectedFilters[key] = parseInt(value);
      } else {
        delete this.selectedFilters[key];
      }
    }
    if (!this.selectedFilters[key]) {
      if (parseInt(value) > 0) {
        this.selectedFilters[key] = parseInt(value);
      }
    }

    this.searchForVariants();
  }

  searchForVariants() {
    let variants = this.selectedProduct.variants;
    let filters = Object.values(this.selectedFilters);

    filters.forEach((filter) => {
      variants = variants.filter((x) =>
        x.variation.attribute_value_ids.includes(filter)
      );
    });

    if (
      !variants.find((x) => {
        x.gtin === this.variant;
      })
    ) {
      this.variant = null;
    }

    this.filteredVariations = variants;
  }

  checkDisabled(key, value) {
    if (this.filters == {}) {
      return null;
    }
    if (this.selectedFilters[key]) {
      return null;
    }
    if (
      this.filteredVariations.find((x) =>
        x.variation.attribute_value_ids.includes(parseInt(value))
      )
    ) {
      return null;
    }
    return true;
  }
}
