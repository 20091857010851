<app-header [landing]="true"></app-header>
<div class="frequently-area">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="frequently-accordion">
          <div id="accordion">
            <div
              class="card open"
              [ngClass]="{
                actives: item.isCollapsed
              }"
              *ngFor="let item of FAQ; let i = index"
            >
              <div class="card-header" >
                <h5 class="mb-0">
                  <a
                    class="collapsed"
                    [ngClass]="{
                      collapsed: !item.isCollapsed
                    }"
                    (click)="item.isCollapsed = !item.isCollapsed"
                  >
                    {{ item.question }}
                  </a>
                </h5>
              </div>
              <!-- toggle answer -->
              <div
                [id]="'collapse' + i"
                class=" answer collapse expand-answer-hide"
                [ngClass]="{
                  'expand-answer-show': item.isCollapsed
                }"
              >
                <div i18n class="card-body">
                  {{ item.answer }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
