<div class="account-page-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <ul class="nav myaccount-tab-trigger" id="account-page-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link" id="account-dashboard-tab" [routerLink]="['dash']"
                            routerLinkActive="active" i18n>Dashboard</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="account-orders-tab" [routerLink]="['orders']"
                            routerLinkActive="active" i18n>Orders</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="account-address-tab" [routerLink]="['address']"
                            routerLinkActive="active" i18n>Addresses</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="account-address-tab" [routerLink]="['favorites']"
                            routerLinkActive="active" i18n>Favorites</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="account-details-tab" [routerLink]="['details']"
                            routerLinkActive="active" i18n>Account Details</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="account-logout-tab" (click)="logout()" i18n>Logout</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-9">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>