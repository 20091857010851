<div class="slide-item">
  <div class="sp-area p-0">
    <div class="container">
      <div class="sp-nav p-0">
        <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-6">
            <p-galleria [value]="selectedProduct.attachments">
              <ng-template pTemplate="item" let-att>
                <lib-ngx-image-zoom
                  enableLens="true"
                  lensWidth="150"
                  lensHeight="150"
                  [thumbImage]="imagePath + att.path"
                  [fullImage]="imagePath + att.path"
                >
                </lib-ngx-image-zoom>
              </ng-template>
              <ng-template pTemplate="thumbnail" let-att>
                <div class="p-grid p-nogutter p-justify-between">
                  <div class="thumbnail-container">
                    <img [src]="imagePath + att.path" />
                  </div>
                </div>
              </ng-template>
            </p-galleria>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-6">
            <div class="sp-content">
              <div class="sp-heading">
                <h5>
                  <a>{{ item.name }}</a>
                </h5>
              </div>
              <span class="reference" i18n>Code: {{ item.code }}</span>
              <div class="rating-box"></div>
              <div class="sp-essential_stuff">
                <ul>
                  <li *ngIf="price>0">
                    <ng-container i18n>Price:</ng-container>
                    <a
                      ><span>{{
                        price | currency: "ARS":"symbol-narrow":"1.2-2"
                      }}</span></a
                    >
                  </li>
                  <li>
                    <ng-container i18n> Category:</ng-container>
                    <a *ngFor="let cat of item.categories">{{ cat.name }} </a>
                  </li>
                  <li>
                    <ng-container i18n>Description:</ng-container>
                    <a>{{ item.description }}</a>
                  </li>
                </ul>
              </div>
              <div class="filters-box-wrapper" *ngIf="selectedProduct.variants.length>1">
                <div
                  *ngFor="let attribute of filters | keyvalue"
                  class="filter-box"
                >
                  <div class="product-size_box">
                    <span>{{ attribute.value.name }}</span>
                    <select
                      class="myniceselect nice-select"
                      (change)="
                        filterSelect($event.target.value, attribute.key)
                      "
                    >
                      <option value="0" i18n>Choose</option>
                      <option
                        *ngFor="let opt of attribute.value.values"
                        value="{{ opt.id }}"
                        [disabled]="checkDisabled(attribute.key, opt.id)"
                      >
                        {{ opt.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div
                *ngIf="
                  (filteredVariations.length <
                    selectedProduct.variants.length &&
                    selectedProduct.variants.length > 1)
                "
                class="d-flex py-2"
              >
                <div
                  class="filtered-variation"
                  *ngFor="let variation of filteredVariations"
                >
                  <p-radioButton
                    #radio
                    name="variation"
                    [value]="variation.id"
                    [(ngModel)]="variant"
                    (onClick)="getVariant(variation)"
                    [pTooltip]="variation.tooltip"
                    label="{{ variation.tooltip }}"
                  ></p-radioButton>
                </div>
              </div>

              <div class="quantity d-flex align-items-center">
                <label i18n>Quantity</label>
                <div class="cart-plus-minus">
                  <input
                    [(ngModel)]="quantity"
                    class="cart-plus-minus-box"
                    value="1"
                    type="text"
                  />
                  <div class="dec qtybutton" (click)="quantityMinus()">
                    <i class="fa fa-angle-down"></i>
                  </div>
                  <div class="inc qtybutton">
                    <i (click)="quantityPlus()" class="fa fa-angle-up"></i>
                  </div>
                </div>
                <div class="text-danger pl-2" i18 *ngIf="maxQuantity<=0">
                  Out Of Stock
                </div>
              </div>
              <div class="qty-btn_area">
                <label i18n for="">Comment</label>
                <textarea [(ngModel)]="comment" class="form-control"></textarea>
              </div>
              <div class="qty-btn_area">
                <ul>
                  <li>
                    <a
                      class="qty-cart_btn"
                      [ngClass]="{ disabled: !variant }"
                      (click)="addToCart()"
                      i18n
                      >Add To Cart</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
