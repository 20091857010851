import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-preorder-dialog',
  templateUrl: './preorder-dialog.component.html',
  styleUrls: ['./preorder-dialog.component.scss']
})
export class PreorderDialogComponent implements OnInit {

  constructor(public ref: DynamicDialogRef) { }

  ngOnInit(): void {
  }

}
