import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-order-product-dialog',
  templateUrl: './order-product-dialog.component.html',
  styleUrls: ['./order-product-dialog.component.scss']
})
export class OrderProductDialogComponent implements OnInit {

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit(): void {
  }

}
