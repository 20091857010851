import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@env/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  API_URL = environment.api_url;
  readonly url = 'login';
  constructor(private http: HttpClient, private router:Router, private jwtHelper:JwtHelperService) {

  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('sofd_token');
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }

  getToken() {
    return localStorage.getItem('sofd_token');
  }

  login(category) {
    return this.http.post(this.API_URL + this.url, category).pipe();
  }

  register(registration) {
    return this.http.post(this.API_URL + 'register', registration).pipe();
  }
  forgot(email) {
    return this.http.post(this.API_URL + 'forgot', email).pipe();
  }

  logout(){
    return this.http.post(this.API_URL + 'logout', null).pipe(tap(x=>{
      localStorage.removeItem('sofd_token')
    })).subscribe(res=>{this.router.navigate(['/login']);return true});
  }

}
