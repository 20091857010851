<ng-container *ngIf="!loading && payment">
  <div>
    <i class="fa-check fa"></i>
  </div>
  <div>
    <p i18n>Your Order is placed</p>
  </div>
  <div>
    <button i18n (click)="redirect()">OK</button>
  </div>
</ng-container>
<ng-container *ngIf="!loading && error">
    <div>
      <i class="fa-times fa"></i>
    </div>
    <div>
      <p i18n>Something went wrong!</p>
    </div>
    <div>
      <button i18n (click)="redirect()">OK</button>
    </div>
  </ng-container>
<ng-container *ngIf="loading">
  <div>
    <i class="fa fa-spin fa-circle-notch"></i>
  </div>
</ng-container>
