<div
  class="tab-pane fade show"
  id="account-orders"
  role="tabpanel"
  aria-labelledby="account-orders-tab"
>
  <div class="myaccount-orders">
    <h4 class="small-title" i18n>MY ORDERS</h4>
    <div class="table-responsive">
      <table class="table table-bordered table-hover">
        <tbody>
          <tr>
            <th i18n>ORDER</th>
            <th i18n>DATE</th>
            <th i18n>PAYMENT STATUS</th>
            <th i18n>STATUS</th>
            <th i18n>TOTAL</th>
            <th></th>
          </tr>
          <tr *ngFor="let order of orders">
            <td>
              <a class="account-order-id" href="javascript:void(0)"
                >#{{ order.id }}</a
              >
            </td>
            <td>{{ order.date | date:"yyyy-MM-d HH:mm" }}</td>
            <td>
              <ng-container *ngIf="order?.preorder === 1" i18n>
                Preorder
              </ng-container>
              <ng-container *ngIf="order?.preorder === 0">
                {{ order.bank_status }}
              </ng-container>
            </td>
            <td>{{ order.status }}</td>
            <td>{{ order.total_price }}</td>
            <td>
              <a
                href="javascript:void(0)"
                class="hiraola-btn hiraola-btn_dark hiraola-btn_sm"
                (click)="show(order)"
                ><span i18n>View</span></a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
