<div class="hiraola-login-register_area">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12 m-auto col-lg-6 col-xs-12">
                <form [formGroup]="registerForm">
                    <div class="login-form"> 
                        <h4 class="login-title" ><ng-container i18n> Recuperar Contraseña</ng-container>  / <a i18n [routerLink]="['/login']" routerLinkActive="router-link-active" >Login</a> </h4>
                        <div class="row">
                            <div class="col-md-12">
                                <label i18n>Email Address*</label>
                                <input formControlName="email" type="email">
                            </div>
                            <div class="col-12">
                                <button (click)="submit()" class="hiraola-register_btn" i18n>Recuperar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>