import { Component, OnInit } from '@angular/core';
import { SiteService } from '@siteServices/site/site.service';
import { take } from 'rxjs/operators';

import { DialogService } from 'primeng/dynamicdialog';
import {OrderProductDialogComponent} from '../../order-product-dialog/order-product-dialog.component'

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  providers:[DialogService]
})
export class OrdersComponent implements OnInit {

  orders: any;
  constructor(siteService:SiteService,private dialogService:DialogService) { 
    siteService.getOrders().pipe(take(1)).subscribe(res=>{
      this.orders = res;
    })
  }

  ngOnInit(): void {
  }
  show(item){
    this.dialogService.open(OrderProductDialogComponent, {
      header: $localize`Order Products`,
      width: '40%',
      data: { entity: item.cart_products },
    }).onClose.subscribe(res => {
    });;
  }

}
