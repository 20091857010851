import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@siteServices/auth/auth.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  registerForm: FormGroup;
  constructor(fb: FormBuilder, private authService: AuthService, private router: Router) {
    if(this.authService.isAuthenticated()){
      this.router.navigate(['/shop']);
    }
    this.registerForm = fb.group({
      email: [null, [Validators.required, Validators.email]],

    })
  }

  ngOnInit(): void {
  }

  submit() {
    
    if (this.registerForm.valid) {
      this.authService.forgot(this.registerForm.value).pipe(take(1)).subscribe(res => {

        this.router.navigate(['/login'])
      })

    }
  }

}
