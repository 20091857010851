import { Component, OnInit } from '@angular/core';
import { AuthService } from '@siteServices/auth/auth.service';
import { SiteService } from '@siteServices/site/site.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.scss']
})
export class DashComponent implements OnInit {

  user: any;
  constructor(siteService:SiteService,private auth:AuthService) { 
    siteService.getUserDetails().pipe(take(1)).subscribe(res=>{
      this.user = res;
    })
  }

  ngOnInit(): void {
  }
  
  logout(){
    this.auth.logout();
  }

}
