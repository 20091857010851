<div class="table-responsive">
    <table class="table table-bordered table-hover">
        <tbody>
            <tr>
                <th>#</th>
                <th i18n>Name</th>
                <th i18n>Code</th>
                <th i18n>Quantity</th>
                <th i18n>Unit Price</th>
                <th i18n>Total Price</th>
            </tr>
            <tr *ngFor="let product of config.data.entity;let i=index">
                <td>{{i+1}}</td>
                <td>{{product.product_name}}</td>
                <td>{{product.gtin}}</td>
                <td>{{product.quantity}}</td>
                <td>{{product.price | currency:'ARS':'symbol-narrow':'1.2-2'}}</td>
                <td>{{product.total_price | currency:'ARS':'symbol-narrow':'1.2-2'}}</td>
            </tr>
        </tbody>
    </table>
</div>