import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SiteRoutingModule } from './site-routing.module';
import { SiteComponent } from './site.component';
import { LayoutsModule } from './layouts/layouts.module';
import { LandingComponent } from './pages/landing/landing.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AccountComponent } from './pages/account/account.component';
import { DashComponent } from './pages/account/dash/dash.component';
import { OrdersComponent } from './pages/account/orders/orders.component';
import { AddressComponent } from './pages/account/address/address.component';
import { DetailsComponent } from './pages/account/details/details.component';
import { CarouselModule } from 'primeng/carousel';
import { ShopComponent } from './pages/shop/shop.component';
import { SliderModule } from 'primeng/slider';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import {GalleriaModule} from 'primeng/galleria';
import { CartComponent } from './pages/cart/cart.component';
import { OrderProductDialogComponent } from './pages/order-product-dialog/order-product-dialog.component';
import {SidebarModule} from 'primeng/sidebar';
import { ProductDialogComponent } from './pages/shop/product-dialog/product-dialog.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import {RadioButtonModule} from 'primeng/radiobutton';
import {TooltipModule} from 'primeng/tooltip';
import { PaymentErrorComponent } from './pages/account/payment-error/payment-error.component';
import { PaymentSuccessComponent } from './pages/account/payment-success/payment-success.component';
import { PreorderDialogComponent } from './pages/cart/preorder-dialog/preorder-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';
import { HelpComponent } from './pages/help/help.component';
import { FavoritesComponent } from './pages/account/favorites/favorites.component';




@NgModule({
  declarations: [SiteComponent, LandingComponent, LoginComponent, RegisterComponent, AccountComponent, DashComponent, OrdersComponent, AddressComponent, DetailsComponent, ShopComponent, CartComponent, OrderProductDialogComponent, ProductDialogComponent, ForgotPasswordComponent, PaymentErrorComponent, PaymentSuccessComponent, PreorderDialogComponent, HelpComponent, FavoritesComponent],
  imports: [
    CommonModule,
    SiteRoutingModule,
    LayoutsModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    CarouselModule,
    SliderModule,
    NgxImageZoomModule,
    GalleriaModule,
    SidebarModule,
    RadioButtonModule,
    TooltipModule
  ],
  providers:[DialogService]
})
export class SiteModule { }
