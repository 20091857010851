import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { SiteService } from '@siteServices/site/site.service';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { take } from 'rxjs/operators';
import { ProductDialogComponent } from '../../shop/product-dialog/product-dialog.component';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
})
export class FavoritesComponent implements OnInit {
  products;
  imagePath = environment.base;
  constructor(
    private dialogService: DialogService,
    private siteService: SiteService,
    private toast: MessageService
  ) {}

  ngOnInit(): void {
    this.getProducts();
  }

  getProducts() {
    this.siteService
      .getFavorites()
      .pipe(take(1))
      .subscribe((res) => {
        this.products = res;
      });
  }

  addToCart(event, item) {
    event.stopPropagation();
    let send = {
      quantity: 1,
      product_id: item.id,
      comment: '',
      variant_id: item.variants[0].id,
    };
    this.siteService.addToCart(send).subscribe((res) => {
      this.toast.add({
        severity: 'success',
        summary: $localize`Success`,
        detail: $localize`Added to cart`,
      });
    });
  }
  productPage(item) {
    this.dialogService
      .open(ProductDialogComponent, {
        styleClass: 'product-page',
        width: '1100px',
        style: { 'max-width': '100vw' },
        data: { entity: item },
      })
      .onClose.subscribe((res) => {
        this.getProducts();
      });
  }
}
