
    <div>
        <i class="fa-check fa"></i>
    </div>
    <div>
        <p i18n>Your Order is placed </p>
    </div>
    <div>
        <button i18n (click)="ref.close()">OK</button>
    </div>




