<div class="loading" *ngIf="!data">
    <div class="text-center middle">
        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>
<app-header [landing]="true"></app-header>

<div class="slider-with-category_menu">
    <div class="container-fluid">
        <div class="row template-color-1">
            <div class="col-12 main-slider">
                <div class="hiraola-slider_area" *ngIf="data">
                    <p-carousel [value]="data.slide" [numVisible]="1" [numScroll]="1">
                        <ng-template let-slide pTemplate="item">
                            <div [ngStyle]="{'background-image': 'url('+imgPath+slide+')'}"
                                class="single-slide animation-style-01 bg-1 d-flex align-items-center">
                                <div class="container ">

                                </div>
                            </div>
                        </ng-template>
                    </p-carousel>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="hiraola-banner_area" *ngIf="data">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4">
                <div class="banner-item img-hover_effect">
                    <a>
                        <img class="img-full" src="{{imgPath+data.banners[0]}}">
                    </a>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="banner-item img-hover_effect">
                    <a>
                        <img class="img-full" src="{{imgPath+data.banners[1]}}">
                    </a>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="banner-item img-hover_effect">
                    <a href="#">
                        <img class="img-full" src="{{imgPath+data.banners[2]}}">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Begin Hiraola's Product Area -->
<div class="hiraola-product_area" *ngIf="data">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="hiraola-section_title">
                    <h4 i18n>New Arrival</h4>
                </div>
            </div>
            <div class="col-12">
                <div class="hiraola-product_slider">
                    <p-carousel [value]="data.new" [numVisible]="3" [numScroll]="1">
                        <ng-template let-item pTemplate="item">
                            <div class="slide-item">
                                <div class="single_product">
                                    <div style="cursor: pointer;" class="product-img" [routerLink]="['shop']" [queryParams]="{category: item.categories[0]?.id}">
                                        <a>
                                            <img class="primary-img" src="{{imgPath+item.attachments[0].path}}">
                                            <img class="secondary-img"
                                                src="{{imgPath+item.attachments[item.attachments.length-1].path}}">
                                        </a>

                                    </div>
                                    <div class="hiraola-product_content">
                                        <div class="product-desc_info">
                                            <h6><a class="product-name">{{item.name}}</a></h6>
                                            <div class="price-box">
                                                <span class="new-price">{{item.price |currency:'ARS':'symbol-narrow':'1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </p-carousel>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- Hiraola's Product Area End Here -->

<div class="static-banner_area" *ngIf="data">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div [ngStyle]="{'background-image': 'url('+imgPath+data.banners[3]+')'}" class="static-banner-image">
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Begin Hiraola's Product Tab Area -->
<div class="hiraola-product-tab_area-2" *ngIf="data">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="product-tab">
                    <div class="hiraola-tab_title">
                        <h4 i18n>New Products</h4>
                    </div>
                    <!-- <ul class="nav product-menu">
                        <li><a class="active" data-toggle="tab" href="#necklaces"><span>Necklaces</span></a></li>
                        <li><a data-toggle="tab" href="#earrings"><span>Earrings</span></a></li>
                        <li><a data-toggle="tab" href="#bracelet"><span>Bracelet</span></a></li>
                        <li><a data-toggle="tab" href="#anklet"><span>Anklet</span></a></li>
                    </ul> -->
                </div>
                <div class="hiraola-product_slider">
                    <p-carousel [value]="data.best_sellers" [numVisible]="3" [numScroll]="1">
                        <ng-template let-item pTemplate="item">
                            <div class="slide-item">
                                <div class="single_product">
                                    <div style="cursor: pointer;" class="product-img">
                                        <a [routerLink]="['shop']" [queryParams]="{category: item.categories[0]?.id}">
                                            <img class="primary-img" src="{{imgPath+item.attachments[0].path}}">
                                            <img class="secondary-img"
                                                src="{{imgPath+item.attachments[item.attachments.length-1].path}}">
                                        </a>

                                    </div>
                                    <div class="hiraola-product_content">
                                        <div class="product-desc_info">
                                            <h6><a class="product-name">{{item.name}}</a></h6>
                                            <div class="price-box">
                                                <span class="new-price">{{item.price}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </p-carousel>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Hiraola's Product Tab Area End Here -->

<div class="hiraola-banner_area-2" *ngIf="data">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="banner-item img-hover_effect">
                    <a>
                        <img class="img-full" src="{{imgPath+data.banners[4]}}">
                    </a>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="banner-item img-hover_effect">
                    <a href="">
                        <img class="img-full" src="{{imgPath+data.banners[5]}}">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Begin Hiraola's Project Countdown Area -->
<div class="project-count-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-count text-center">
                    <div class="count-icon">
                        <span class="ion-ios-briefcase-outline"></span>
                    </div>
                    <div class="count-title">
                        <h2 class="count">2169</h2>
                        <span i18n>Project Done</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-count text-center">
                    <div class="count-icon">
                        <span class="ion-ios-wineglass-outline"></span>
                    </div>
                    <div class="count-title">
                        <h2 class="count">869</h2>
                        <span i18n>Awards Winned</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-count text-center">
                    <div class="count-icon">
                        <span class="ion-ios-lightbulb-outline"></span>
                    </div>
                    <div class="count-title">
                        <h2 class="count">689</h2>
                        <span i18n>Hours Worked</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-count text-center">
                    <div class="count-icon">
                        <span class="ion-happy-outline"></span>
                    </div>
                    <div class="count-title">
                        <h2 class="count">2169</h2>
                        <span i18n>Happy Customer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Hiraola's Project Countdown Area End Here -->



<!-- Begin Contact Main Page Area -->
<div class="contact-main-page">

    <!-- <div class="container">
        <div class="hiraola-tab_title">
            <h4 i18n>CONTACTS</h4>
        </div>
        <div id="google-map">
            map
        </div>
    </div> -->
    <div class="container">
        <div class="row">
            <div class="col-lg-5 offset-lg-1 col-md-12 order-1 order-lg-2">
                <div class="contact-page-side-content">
                    <h3 class="contact-page-title" i18n>Contact Us</h3>
                    <p class="contact-page-message" i18n>Claritas est etiam processus dynamicus, qui sequitur
                        mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum
                        claram anteposuerit litterarum formas human.</p>
                    <div class="single-contact-block">
                        <h4><i class="fa fa-fax"></i>
                            <ng-container i18n> Address</ng-container>
                        </h4>
                        <p i18n>Libertad 243 Local 29 , CABA , Argentina</p>
                    </div>
                    <div class="single-contact-block">
                        <h4><i class="fa fa-phone"></i>
                            <ng-container i18n> Phone</ng-container>
                        </h4>
                        <p i18n>Mobile: +54 11 4382 2426</p>
                        <p i18n>Mobile: +54 11 4382 9518</p>
                    </div>
                    <div class="single-contact-block last-child">
                        <h4><i class="fa fa-envelope-o"></i>
                            <ng-container i18n> Email</ng-container>
                        </h4>
                        <p>info@sofiadesign.com.ar</p>
                        <p>soporte@sofiadesign.com.ar</p>
                        <p>ventas@sofiadesign.com.ar</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 order-2 order-lg-1">
                <div class="contact-form-content">
                    <h3 class="contact-page-title" i18n>Tell Us Your Message</h3>
                    <div class="contact-form">
                        <form id="contact-form" action="http://hasthemes.com/file/mail.php" method="post">
                            <div class="form-group">
                                <label>
                                    <ng-container i18n>Your Name</ng-container> <span class="required">*</span>
                                </label>
                                <input type="text" name="con_name" id="con_name" required>
                            </div>
                            <div class="form-group">
                                <label>
                                    <ng-container i18n>Your Email</ng-container> <span class="required">*</span>
                                </label>
                                <input type="email" name="con_email" id="con_email" required>
                            </div>
                            <div class="form-group">
                                <label i18n>Subject</label>
                                <input type="text" name="con_subject" id="con_subject">
                            </div>
                            <div class="form-group form-group-2">
                                <label i18n>Your Message</label>
                                <textarea name="con_message" id="con_message"></textarea>
                            </div>
                            <div class="form-group">
                                <button type="submit" value="submit" id="submit" class="alsita-contact-form_btn"
                                    name="submit" i18n>send</button>
                            </div>
                        </form>
                    </div>
                    <p class="form-messege"></p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Main Page Area End Here -->


<!-- Begin Hiraola's Shipping Area -->
<!-- <div class="hiraola-shipping_area mt-5">
    <div class="container">
        <div class="shipping-nav">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="shipping-item">
                        <div class="shipping-icon">
                            <img src="assets/images/shipping-icon/1.png" alt="Hiraola's Shipping Icon">
                        </div>
                        <div class="shipping-content">
                            <h6 i18n>Free Uk Standard Delivery</h6>
                            <p i18n>Designated day delivery</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="shipping-item">
                        <div class="shipping-icon">
                            <img src="assets/images/shipping-icon/2.png" alt="Hiraola's Shipping Icon">
                        </div>
                        <div class="shipping-content">
                            <h6 i18n>Freshyly Prepared Ingredients</h6>
                            <p i18n>Made for your delivery date</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="shipping-item">
                        <div class="shipping-icon">
                            <img src="assets/images/shipping-icon/3.png" alt="Hiraola's Shipping Icon">
                        </div>
                        <div class="shipping-content">
                            <h6 i18n>98% Of Anta Clients</h6>
                            <p i18n>Reach their personal goals set</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="shipping-item">
                        <div class="shipping-icon">
                            <img src="assets/images/shipping-icon/4.png" alt="Hiraola's Shipping Icon">
                        </div>
                        <div class="shipping-content">
                            <h6 i18n>Winner Of 15 Awards</h6>
                            <p i18n>Healthy food and drink 2019</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-footer></app-footer>