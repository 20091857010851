<header class="header-main_area">
  <div class="header-top_area">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="ht-left_area">
            <div class="header-shipping_area">
              <ul>
                <li>
                  <span i18n>Telephone Enquiry: </span>
                  <a class="mr-3" href="callto://+541143822426"
                    >+54 11 4382 2426
                  </a>
                  <a href="callto://+541143829518"> +54 11 4382 9518</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="ht-right_area">
            <div class="ht-menu">
              <ul>
                <li>
                  <a
                    [routerLink]="['/account']"
                    routerLinkActive="router-link-active"
                    ><span i18n>My Account</span
                    ><i class="fa fa-chevron-down"></i
                  ></a>
                  <ul class="ht-dropdown ht-my_account" *ngIf="!isLoggedIn">
                    <li>
                      <a [routerLink]="['/registration']" i18n>Register</a>
                    </li>
                    <li>
                      <a [routerLink]="['/login']" i18n>Login</a>
                    </li>
                  </ul>
                  <ul class="ht-dropdown ht-my_account" *ngIf="isLoggedIn">
                    <li style="cursor: pointer;" (click)="logout()">
                      <a i18n>CERRAR SESIÓN</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="header-middle_area d-none d-lg-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div class="header-logo">
            <a [routerLink]="['/']">
              <img src="assets/images/menu/logo/1.png" />
            </a>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="hm-form_area">
            <div class="hm-searchbox">
              <input type="text" #ss (keyup)="getKey(ss.value)" />
              <button
                class="li-btn"
                [routerLink]="['/shop']"
                [queryParams]="{ key: search }"
                queryParamsHandling="merge"
                routerLinkActive="router-link-active"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="header-bottom_area header-sticky stick"
    [ngClass]="{ 'header-fixed': fixed }"
  >
    <div class="px-5 container header-container">
      <div class="row">
        <div class="col-md-4 col-sm-4 col-lg-1">
          <div class="header-logo">
            <a [routerLink]="['/shop']">
              <img
                src="assets/images/menu/logo/2.png"
                [ngClass]="{ 'd-lg-none': !fixed }"
                alt="Sofia Design Header Logo"
              />
            </a>
          </div>
        </div>
        <div class="col-lg-9 d-none d-lg-block position-static">
          <div class="main-menu_area">
            <nav *ngIf="landing">
              <ul>
                <li class="dropdown-holder">
                  <a [routerLink]="['/']" i18n>Home</a>
                </li>
                <li class="dropdown-holder">
                  <a
                    [routerLink]="['/shop']"
                    routerLinkActive="router-link-active"
                    i18n
                    >Shop</a
                  >
                </li>
                <li><a (click)="scroll(2700)" i18n>About Us</a></li>
                <li><a (click)="scroll(3700)" i18n>Contact</a></li>
              </ul>
            </nav>
            <nav *ngIf="!landing">
              <ul>
                <li class="megamenu-holder" *ngFor="let category of categories">
                  <a
                    [routerLink]="['/shop']"
                    [queryParams]="{ category: category.data.id }"
                    queryParamsHandling="merge"
                    [class]="checkRouterActive(category.data.id)"
                    >{{ category.data.name }}</a
                  >
                  <ul
                    class="hm-megamenu"
                    *ngIf="category.children.length"
                    [ngStyle]="{ width: 190 * category.children.length + 'px' }"
                  >
                    <li>
                      <span class="megamenu-title"
                        ><a
                          i18n
                          [routerLink]="['/shop']"
                          [queryParams]="{ category: category.data.id }"
                          queryParamsHandling="merge"
                          [class]="checkRouterActive(category.data.id,true)"
                          >TODOS</a
                        ></span
                      >
                    </li>
                    <li *ngFor="let c1 of category.children">
                      <span class="megamenu-title"
                        ><a
                          [routerLink]="['/shop']"
                          [queryParams]="{ category: c1.data.id }"
                          queryParamsHandling="merge"
                          routerLinkActive="router-link-active"
                          >{{ c1.data.name }}</a
                        ></span
                      >
                      <ul>
                        <li *ngFor="let c2 of c1.children">
                          <a
                            [routerLink]="['/shop']"
                            routerLinkActive="router-link-active"
                            [queryParams]="{ category: c2.data.id }"
                            queryParamsHandling="merge"
                            >{{ c2.data.name }}</a
                          >
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="col-lg-2 col-md-8 col-sm-8">
          <div class="header-right_area">
            <ul>
              <li>
                <a
                  [routerLink]="['/account']"
                  routerLinkActive="router-link-active"
                  class="minicart-btn toolbar-btn"
                >
                  <i class="fa fa-user-circle"></i>
                </a>
              </li>
              <li>
                <a
                  (click)="sidebar = 'menu'; visible = true"
                  class="mobile-menu_btn toolbar-btn color--white d-lg-none d-block"
                >
                  <i class="ion-navicon"></i>
                </a>
              </li>
              <li class="cart-button">
                <a
                  (click)="getCart(); visible = true; sidebar = 'cart'"
                  class="minicart-btn toolbar-btn"
                >
                  <i class="ion-bag"></i>
                  <span class="cart-count">{{ cartCount }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-sidebar [(visible)]="visible" position="right">
    <div
      *ngIf="sidebar == 'cart'"
      class="offcanvas-minicart_wrapper open"
      id="miniCart"
    >
      <div class="offcanvas-menu-inner cart">
        <a (click)="visible = false" class="btn-close btn-menu"
          ><i class="ion-android-close"></i
        ></a>
        <div class="minicart-content">
          <div class="minicart-heading">
            <h4 i18n>Shopping Cart</h4>
          </div>
          <ul class="minicart-list">
            <li *ngFor="let item of cart" class="minicart-product">
              <a class="product-item_remove" (click)="removeProduct(item)"
                ><i class="ion-android-close"></i
              ></a>
              <div class="product-item_img">
                <img src="{{ imgPath + item.attachment.path }}" />
              </div>
              <div class="product-item_content">
                <a class="product-item_title" href="shop-left-sidebar.html">{{
                  item.name
                }}</a>
                <span class="product-item_quantity"
                  >{{ item.quantity }} x {{ item.unit_price }}</span
                >
              </div>
            </li>
          </ul>
        </div>
        <div class="minicart-item_total">
          <span i18n>Subtotal</span>
          <span class="ammount">{{ total }}</span>
        </div>
        <div class="minicart-btn_area">
          <a
            i18n
            [routerLink]="['/cart']"
            class="hiraola-btn hiraola-btn_dark hiraola-btn_fullwidth"
            >Cart</a
          >
        </div>
      </div>
    </div>

    <div *ngIf="sidebar == 'menu'" class="d-block show">
      <div class="offcanvas-menu-inner">
        <div class="container">
          <a (click)="visible = false" class="btn-close btn-menu"
            ><i class="ion-android-close"></i
          ></a>
          <!-- <a (click)="visible=false" class="btn-close btn-cart"><i class="ion-android-close"></i></a> -->
          <nav class="offcanvas-navigation">
            <ul class="mobile-menu" *ngFor="let category of categories">
              <li class="menu-item-has-children">
                <span
                  (click)="category.expanded = !category.expanded"
                  class="menu-expand"
                >
                  <i
                    class="fa fa-plus"
                    [ngClass]="{
                      'fa-plus': !category.expanded,
                      'fa-minus': category.expanded
                    }"
                  ></i
                ></span>
                <a
                  (click)="visible = false"
                  [routerLink]="['/shop']"
                  [queryParams]="{ category: category.data.id }"
                  queryParamsHandling="merge"
                >
                  <span class="mm-text">{{ category.data.name }}</span>
                </a>
                <ul
                  *ngFor="let c1 of category.children"
                  class="sub-menu active"
                  [ngClass]="{
                    'menu-close': !category.expanded,
                    'menu-open': category.expanded
                  }"
                >
                  <li class="menu-item-has-children">
                    <a
                      (click)="visible = false"
                      [routerLink]="['/shop']"
                      [queryParams]="{ category: c1.data.id }"
                      queryParamsHandling="merge"
                      routerLinkActive="router-link-active"
                    >
                      <span class="mm-text">{{ c1.data.name }}</span>
                    </a>
                    <ul class="sub-menu">
                      <li *ngFor="let c2 of c1.children">
                        <a
                          (click)="visible = false"
                          [routerLink]="['/shop']"
                          [queryParams]="{ category: c2.data.id }"
                          queryParamsHandling="merge"
                        >
                          <span class="mm-text">{{ c2.data.name }}</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </p-sidebar>
  <!-- <a  > -->
  <div class="waBlock">
    <div class="assist-block">
      <p i18n>Need assistance ? Connect with our Support</p>
    </div>
    <div class="whatsapp">
      <i class="fab fa-whatsapp"></i>
    </div>
    <div class="whatsappNumber">
      <div class="whatsapp-icon">
        <a href="https://wa.me/541143822426" target="_blank">
          <i class="fab fa-whatsapp-square"></i>
        </a>
      </div>
      <div class="whatsapp-icon">
        <a href="https://wa.me/541143829518" target="_blank">
          <i class="fab fa-whatsapp-square"></i>
        </a>
      </div>
    </div>
  </div>
  <!-- </a> -->
</header>

<!-- <a href="tel:+54 11 4382 2426">54 11 4382 2426</a> 
<a href="tel:+54 11 4382 9518">54 11 4382 9518</a> -->
