import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@siteServices/auth/auth.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  registerForm: FormGroup;
  loginErrors = { invalid: false, not_active: false };
  constructor(
    fb: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/shop']);
    }

    this.loginForm = fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
      remember: [null],
    });
  }

  ngOnInit(): void {}

  login() {
    if (this.loginForm.valid) {
      this.authService
        .login(this.loginForm.value)
        .pipe(take(1))
        .subscribe(
          (res) => {
            //@ts-ignore
            localStorage.setItem('sofd_token', res.data.token);
            this.router.navigate(['/shop']);
          },
          (error) => {
            if (error.error.data.message == 'Invalid Credentials') {
              this.loginErrors.invalid = true;
            } else if (error.error.data.message == 'Not Active') {
              this.loginErrors.not_active = true;
            }
          }
        );
    }
  }
}
