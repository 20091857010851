import { IProduct } from '@adminServices/products/product';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '@env/environment';
import { SiteService } from '@siteServices/site/site.service';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { pipe } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProductDialogComponent } from './product-dialog/product-dialog.component';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
  providers: [DialogService],
})
export class ShopComponent implements OnInit {
  listview = false;
  products;
  imagePath = environment.base;
  filters: any;
  price: number[] = [0, 0];
  selectedProduct;
  quantity = 1;
  categories;
  currentPage = 1;
  stopGetting = false;
  selectedCategory = 'all';
  @ViewChild('pageBottom') pageBottom: ElementRef;
  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (
      window.pageYOffset + 1000 > this.pageBottom.nativeElement.offsetTop &&
      !this.stopGetting
    ) {
      this.getProducts();
    }
  }

  constructor(
    private dialogService: DialogService,
    private siteService: SiteService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: MessageService,
    private cd: ChangeDetectorRef
  ) {
    this.siteService
      .getCategories()
      .pipe(take(1))
      .subscribe((res) => {
        this.categories = res;
      });
  }

  addToCart(event, item) {
    event.stopPropagation();
    let send = {
      quantity: 1,
      product_id: item.id,
      comment: '',
      variant_id: item.variants[0].id,
    };
    this.siteService.addToCart(send).subscribe((res) => {
      this.toast.add({
        severity: 'success',
        summary: $localize`Success`,
        detail: $localize`Added to cart`,
      });
    });
  }
  productPage(item) {
    this.dialogService
      .open(ProductDialogComponent, {
        styleClass: 'product-page',
        width: '1100px',
        style: { 'max-width': '100vw' },
        data: { entity: item },
      })
      .onClose.subscribe((res) => {
        this.getProducts();
      });
  }

  addToFavorite(event) {
    event.stopPropagation();
    alert('favorite');
  }

  getProducts() {
    this.stopGetting = true;
    this.siteService
      .getProducts(this.filters)
      .pipe(take(1))
      .subscribe((res) => {
        res.data.map((x) => {
          x.opened = false;
          this.products.push(x);
          return x;
        });
        this.filters.page = this.filters.page + 1;
        if (res.current_page < res.last_page) {
          this.stopGetting = false;
        }
      });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams) => {
      this.products = [];
      this.filters = { ...queryParams };
      this.filters.page = 1;
      if (this.filters.price_from) {
        this.price[0] = this.filters.price_from;
      }
      if (this.filters.price_to) {
        this.price[1] = this.filters.price_to;
      }

      this.cd.detectChanges();
      this.getProducts();
    });
  }

  setPrices() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { price_from: this.price[0], price_to: this.price[1] },
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  findCategory() {
    this.selectedCategory = 'All';
    if (this.filters.category) {
      this.categories.forEach((element) => {
        let searchResult = this.searchCategory(element, this.filters.category);
        if (searchResult !== null) {
          this.selectedCategory = searchResult.data.name;
        }
      });
    }
    return true;
  }

  searchCategory(element, id) {
    if (element.data.id == id) {
      return element;
    } else if (element.children != null) {
      var i;
      var result = null;
      for (i = 0; result == null && i < element.children.length; i++) {
        result = this.searchCategory(element.children[i], id);
      }
      return result;
    }
    return null;
  }
  pushToFavoriteProducts(item, event) {
    event.stopPropagation();

    this.siteService.favourite(item.id).subscribe((res) => {
      item.is_favourite = !item.is_favourite;
    });
  }
}
