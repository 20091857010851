<div class="hiraola-login-register_area">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12 m-auto col-xs-12 col-lg-6">
                <!-- Login Form s-->
                <form [formGroup]="loginForm">
                    <div class="login-form">
                        <h4 class="login-title" ><ng-container i18n>Login</ng-container>  / <a i18n [routerLink]="['/registration']" routerLinkActive="router-link-active" >Register</a> </h4>
                        <div class="row">
                           
                            <div class="col-md-12 col-12">
                                <label i18n>Email Address*</label>
                                <input formControlName="email" type="email">
                            </div>
                            <div class="col-12 mb--20">
                                <label i18n>Password</label>
                                <input type="password" formControlName="password" >
                            </div>
                            <div class="col-md-8">
                                <div class="check-box">
                                    <input type="checkbox" id="remember_me">
                                    <label for="remember_me" i18n>Remember me</label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="forgotton-password_info">
                                    <a [routerLink]="['/forgot']" routerLinkActive="router-link-active"  i18n> Forgot pasword?</a>
                                </div>
                            </div>
                            <div class="col-md-12 d-flex">
                                <button (click)="login()" class="hiraola-login_btn" i18n>Login</button>
                                <span *ngIf="loginErrors.invalid" class="mt-5 ml-5 text-danger"  i18n>Login Failed. Check Email and Password</span>
                                <span *ngIf="loginErrors.not_active" class="mt-5 ml-5 text-danger"  i18n>Login Failed. The user Is Not active</span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            
        </div>
    </div>
</div>