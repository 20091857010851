<div class="error404-area">
    <div class="col-lg-8 mx-auto text-center">
      <div class="search-error-wrapper">
        <h1 i18n>404</h1>
        <h2 i18n>PAGE NOT BE FOUND</h2>
        <p i18n class="short_desc">
          Sorry but the page you are looking for does not exist, have been
          removed, name changed or is temporarily unavailable.
        </p>
        <form action="javascript:void(0)" class="error-form">
          <div class="inner-error_form">
            <input
              type="text"
              placeholder="Search..."
              class="error-input-text"
            />
            <button type="submit" class="error-search_btn">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </form>
        <div class="hiraola-btn-ps_center"></div>
        <a i18n href="index.html" class="hiraola-error_btn">Back To Home Page</a>
      </div>
    </div>
</div>


