import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@siteServices/auth/auth.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  constructor(fb: FormBuilder, private authService: AuthService, private router: Router) {
    if(this.authService.isAuthenticated()){
      this.router.navigate(['/shop']);
    }
    this.registerForm = fb.group({
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(8)]],
      password_confirmation: [null, [Validators.required, Validators.minLength(8)]],

    }, { validators: this.checkPasswords })
  }

  ngOnInit(): void {
  }

  checkPasswords(group: FormGroup) { 
    const password = group.get('password').value;
    const confirmPassword = group.get('password_confirmation').value;

    return password === confirmPassword ? null : { notSame: true }
  }

  register() {
    
    if (this.registerForm.valid) {
      let send = {
        name: this.registerForm.value.first_name + ' ' + this.registerForm.value.last_name,
        email: this.registerForm.value.email,
        password: this.registerForm.value.password,
        password_confirmation: this.registerForm.value.password_confirmation
      }
      this.authService.register(send).pipe(take(1)).subscribe(res => {
        this.router.navigate(['/login'])
      })

    }
  }

}
