import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { SiteService } from '@siteServices/site/site.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  new = [{ label: 'asdasd' }, { label: 'asdasd' }, { label: 'asdasd' }, { label: 'asdasd' }, { label: 'asdasd' }, { label: 'asdasd' }]
  data;
  imgPath = environment.base
  constructor(private siteService: SiteService) {
    siteService.getSiteData().pipe(take(1)).subscribe(res=>{
      this.data = res;
    })
  }

  ngOnInit(): void {
  }

}
