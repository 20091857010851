import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteService } from '@siteServices/site/site.service';

@Component({
  selector: 'app-payment-error',
  templateUrl: './payment-error.component.html',
  styleUrls: ['./payment-error.component.scss']
})
export class PaymentErrorComponent {

  back_info: any;
  loading = true;
  payment: any;
  error = false;
  constructor(
    route: ActivatedRoute,
    private router: Router,
    private siteService: SiteService
  ) {
    this.back_info = route.snapshot.queryParams;

    if (this.back_info.preference_id) {
      this.siteService.updateOrderStatus(this.back_info).subscribe(
        (res) => {
          this.loading = false;
          this.payment = res;
        },
        (error) => {
          this.loading = false;
          this.error = true;
        }
      );
    }
  }

  redirect() {
    this.router.navigate(['/account/orders']);
  }
}
