<div class="tab-pane fade show" id="account-details" role="tabpanel" aria-labelledby="account-details-tab">
    <div class="myaccount-details">
        <form [formGroup]="form" class="hiraola-form">
            <div class="hiraola-form-inner">
                <div class="single-input">
                    <label for="account-details-firstname" i18n>Name</label>
                    <input formControlName="name" type="text" id="account-details-firstname">
                </div>
                <div class="single-input">
                    <label for="account-details-email" i18n>Email*</label>
                    <input formControlName="email" type="email" id="account-details-email">
                </div>
                <div class="single-input">
                    <label for="account-details-email" i18n>Website</label>
                    <input formControlName="website" type="email" id="account-details-email">
                </div>
                <div class="single-input">
                    <label for="account-details-email" i18n>Phone</label>
                    <input formControlName="phone" type="email" id="account-details-email">
                </div>
                <div class="single-input">
                    <label for="account-details-oldpass" i18n>Current Password(leave blank to leave
                        unchanged)</label>
                    <input formControlName="old_password" type="password" id="account-details-oldpass">
                </div>
                <div class="single-input">
                    <label for="account-details-newpass" i18n>New Password (leave blank to leave
                        unchanged)</label>
                    <input formControlName="password" type="password" id="account-details-newpass">
                </div>
                <div class="single-input">
                    <label for="account-details-confpass" i18n>Confirm New Password</label>
                    <input formControlName="password_confirmation" type="password" id="account-details-confpass">
                </div>
                <div class="single-input">
                    <button class="hiraola-btn hiraola-btn_dark" (click)="save()"><span i18n>SAVE
                    CHANGES</span></button>
                </div>
            </div>
        </form>
    </div>
</div>