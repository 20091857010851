import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SiteModule } from './site/site.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';

import { environment } from '@env/environment';
import { HeaderInterceptor } from './admin/interceptors/header-interceptor';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import {RadioButtonModule} from 'primeng/radiobutton';
import { NotFoundComponent } from './not-found/not-found.component';


export function jwtTokenGetter(request) {
  
    if (request.url.includes("admin")) {
      return localStorage.getItem("admin_token");
    }
    return localStorage.getItem("sofd_token");
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SiteModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastModule,
    DynamicDialogModule,
    RadioButtonModule,
    JwtModule.forRoot({
      config: {
        headerName:'Authorization',
        tokenGetter: jwtTokenGetter,
        authScheme:'Bearer ',
        allowedDomains: [environment.domain],
        // disallowedRoutes: ["http://example.com/examplebadroute/"],
      },
    }),
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
