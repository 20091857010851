<div class="hiraola-login-register_area">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12 m-auto col-lg-6 col-xs-12">
                <form [formGroup]="registerForm">
                    <div class="login-form"> 
                        <h4 class="login-title" ><ng-container i18n>Registration</ng-container>  / <a i18n [routerLink]="['/login']" routerLinkActive="router-link-active" >Login</a> </h4>
                        <div class="row">
                            <div class="col-md-6 col-12 mb--20">
                                <label i18n>First Name*</label>
                                <input formControlName="first_name" type="text">
                            </div>
                            <div class="col-md-6 col-12 mb--20">
                                <label i18n>Last Name*</label>
                                <input formControlName="last_name" type="text">
                            </div>
                            <div class="col-md-12">
                                <label i18n>Email Address*</label>
                                <input formControlName="email" type="email">
                            </div>
                            <div class="col-md-6">
                                <label i18n>Password* </label>
                                <input formControlName="password" type="password">

                            </div>
                            <div class="col-md-6">
                                <label i18n>Confirm Password*</label>
                                <input formControlName="password_confirmation" type="password">
                            </div>
                            <div class="col-12" *ngIf="registerForm.hasError('notSame')">
                                <span class="text-danger" i18n>Passwords are not the same</span>
                            </div>
                            <div class="col-12">
                                <button (click)="register()" class="hiraola-register_btn" i18n>Register</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>